<template>
  <a-modal
    :title="title"
    :width="1024"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="归属项目"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择项目'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择项目"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>

        <a-form-item
          label="表单名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-input
            placeholder="请输入表单名称"
            v-decorator="['name', {rules: [{required: true, message: '请输入表单名称'}]}]"
          />
        </a-form-item>

        <a-form-item label="提交频率" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-row>
            <a-col :span="4">
              <a-form-item label="">
                <a-input-number
                  v-decorator="['frequency', { initialValue: 1, rules: [{ required: true, message: '频率不能为空' }] }]"
                  :min="1"
                  :max="10000"/>
              </a-form-item>
            </a-col>
            <a-col :span="4" style="margin-right: 10px">
              <a-form-item label="">
                <a-select
                  v-decorator="['frequencyUnitItem']"
                  label-in-value
                >
                  <a-select-option v-for="option in frequencyUnitOptions" :key="option.key" :value="option.key">{{option.label}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="">
                <a-input-number
                  v-decorator="['frequencyNum', { initialValue: 1, rules: [{ required: true, message: '次数不能为空' }] }]"
                  :min="1"
                  :max="10000"/>
              </a-form-item>
            </a-col>
            <a-col :span="1">次</a-col>
          </a-row>
        </a-form-item>

        <a-form-item v-if="modalType" label="状态" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-radio-group v-decorator="['status', { initialValue: 1, rules: [{ required: true, message: '状态不能为空' }] }]" >
            <a-radio :value="1">正常</a-radio>
            <a-radio :value="0">停用</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="排序" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
            v-decorator="['sort', { initialValue: 1, rules: [{ required: true, message: '排序不能为空' }] }]"
            :min="1"
            :max="10000"
            style="width: 100%;"/>
        </a-form-item>
        <a-form-item label="表单字段" :label-col="labelCol" :wrapper-col="wrapperCol"></a-form-item>
        <list-item-add :columns="formFieldColumns" ref="form_field_columns"></list-item-add>
        <a-form-item style="margin-top: 30px" label="其它字段" :label-col="labelCol" :wrapper-col="wrapperCol"></a-form-item>
        <list-item-add :columns="otherFieldColumns" ref="other_field_columns"></list-item-add>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { listProjectTree, editFormConfig, addFormConfig } from '@/api/business/patrolConfig'
import pick from 'lodash.pick'
import ListItemAdd from "@/views/business/patrol-config/components/ListItemAdd";

// 表单字段
const fields = [
  'parentId', 'name', 'frequency', 'frequencyUnitItem', 'frequencyNum', 'status', 'sort'
]

const frequencyUnitOptions = [
  {
    label: '天',
    key: 'DAY'
  },
  {
    label: '周',
    key: 'WEEK'
  },
  {
    label: '月',
    key: 'MONTH'
  },
  {
    label: '年',
    key: 'YEAR'
  }
]

// 表单字段表头
const formFieldColumns = [
  {
    title: '字段类型',
    type: 'select',
    dataIndex: 'fieldType',
    width: '100px',
    scopedSlots: { customRender: 'fieldType' },
    // TEXT-文本 TEXT_AREA-长文本 FILE_UPLOAD-文件上传 RATIO-单选框 CHECK_BOX-多选框 LABEL-标签
    options: [
      {
        label: '文本',
        value: 'TEXT'
      },{
        label: '长文本',
        value: 'TEXT_AREA'
      },{
        label: '文件上传',
        value: 'FILE_UPLOAD'
      },{
        label: '单选框',
        value: 'RATIO'
      },{
        label: '多选框',
        value: 'CHECK_BOX'
      },{
        label: '标签',
        value: 'LABEL'
      },
    ]
  },
  {
    title: '字段名称',
    type: "text",
    dataIndex: 'fieldName',
    width: '20%',
    scopedSlots: { customRender: 'fieldName' },
  },
  {
    title: '必填',
    type: 'select',
    dataIndex: 'valid',
    width: '45px',
    scopedSlots: { customRender: 'valid' },
    options: [
      {
        label: '是',
        value: 1
      },{
        label: '否',
        value: 0
      }
    ]
  },
  {
    title: '字段默认值',
    type: "input",
    dataIndex: 'defaultFieldValue',
    width: '20%',
    scopedSlots: { customRender: 'defaultFieldValue' },
  },{
    title: '字典KEY',
    type: "input",
    dataIndex: 'dictKey',
    width: '20%',
    scopedSlots: { customRender: 'dictKey' },
  },
  {
    title: '字段标识',
    type: "select",
    dataIndex: 'fieldFlag',
    width: '100px',
    scopedSlots: { customRender: 'fieldFlag' },
    options: [
      // (正常/异常)
      {
        label: '表单状态',
        value: 'RESULT_STATUS'
      }
    ],
    isClear: true
  },
  {
    title: '备注',
    type: "text",
    dataIndex: 'fieldDetails',
    width: '20%',
    scopedSlots: { customRender: 'fieldDetails' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '140px',
    scopedSlots: { customRender: 'operation' },
  }
]

// 其它字段表头
const otherFieldColumns = [
  {
    title: '字段类型',
    type: 'select',
    dataIndex: 'fieldType',
    width: '15%',
    scopedSlots: { customRender: 'fieldType' },
    // TEXT-文本 IMAGE-图片 FILE-文件 LABEL-标签
    options: [
      {
        label: '文本',
        value: 'TEXT'
      },{
        label: '图片',
        value: 'IMAGE'
      },{
        label: '文件',
        value: 'FILE'
      },{
        label: '标签',
        value: 'LABEL'
      },{
        label: '功能',
        value: 'FUNC'
      },
    ]
  },
  {
    title: '字段名称',
    type: "text",
    dataIndex: 'fieldName',
    width: '25%',
    scopedSlots: { customRender: 'fieldName' },
  },
  {
    title: '字段值',
    type: "text",
    dataIndex: 'fieldValue',
    width: '25%',
    scopedSlots: { customRender: 'fieldValue' },
  },{
    title: '备注',
    type: "text",
    dataIndex: 'fieldDetails',
    width: '20%',
    scopedSlots: { customRender: 'fieldDetails' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '140px',
    scopedSlots: { customRender: 'operation' },
  },
]

// 默认表单值
const formFieldDefault = [
  {
    "fieldId": "-1",
    "fieldName": "巡检项目",
    "fieldType": "LABEL",
    "fieldDetails": "",
    "valid": 0,
    "defaultValue": "",
    "dictKey": "",
    "fieldFlag": "",
    "sort": 0
  },
  {
    "fieldId": "-1",
    "fieldName": "",
    "fieldType": "RATIO",
    "fieldDetails": "",
    "valid": 1,
    "defaultValue": "",
    "dictKey": "bus_patrol_status",
    "fieldFlag": "RESULT_STATUS",
    "sort": 1
  },
  {
    "fieldId": "-1",
    "fieldName": "图片/视频上传",
    "fieldType": "FILE_UPLOAD",
    "fieldDetails": "",
    "valid": 1,
    "defaultValue": "",
    "dictKey": "",
    "fieldFlag": "",
    "sort": 2
  },
  {
    "fieldId": "-1",
    "fieldName": "情况说明",
    "fieldType": "TEXT_AREA",
    "fieldDetails": "",
    "valid": 1,
    "defaultValue": "",
    "dictKey": "",
    "fieldFlag": "",
    "sort": 3
  }
]

export default {
  name: 'SourceDirModal',
  components: {ListItemAdd},
  data () {
    return {
      formFieldColumns,
      otherFieldColumns,
      frequencyUnitOptions,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      // 当前类型 true编辑 false新增
      modalType: true,
      // 编辑id
      id: null,
      // 编辑key
      key: null,
      //  标题
      title: null
    }
  },
  methods: {
    getFrequencyUnitItem(u) {
      let item = frequencyUnitOptions[0]
      if (u == null) {
        return item;
      }
      frequencyUnitOptions.forEach(i => {
        if (i.key === u) {
          item = i
        }
      })
      return item
    },
    /**
     * 修改
     */
    edit (record) {
      this.title = '修改表单'
      this.modalType = true
      this.id = record.id
      this.key = record.key
      this.handleTree()
      this.visible = true
      record['frequencyUnitItem'] = this.getFrequencyUnitItem(record.frequencyUnit)
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick(record, fields))
        this.$refs['other_field_columns'].init(record['otherInfoList'])
        this.$refs['form_field_columns'].init(record['fieldConfigList'])
      })
    },
    /**
     * 添加
     */
    append (currentId) {
      if (currentId == null || currentId == 'undefined') {
        currentId = 0
      }
      this.title = '新建表单'
      this.modalType = false
      this.handleTree()
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick({'parentId': currentId, 'frequencyUnitItem': this.getFrequencyUnitItem(null)}, fields))
        this.$refs['form_field_columns'].setDefaultList(formFieldDefault)
        this.$refs['other_field_columns'].reset()
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values['frequencyUnit'] = values['frequencyUnitItem']['key']
          values['otherInfoList'] = this.$refs['other_field_columns'].getDataList()
          values['fieldConfigList'] = this.$refs['form_field_columns'].getDataList()
          console.log(values)
          if (this.modalType) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 修改提交
     */
    editSubmit (values) {
      editFormConfig(Object.assign(values, { id: this.id })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$notification.success({message: '修改成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    /**
     * 添加提交
     */
    addSubmit(values) {
      addFormConfig(values).then((res) => {
        if (res.code === 200) {
          this.$notification.success({message: '新增成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.modalType = undefined
      this.id = undefined
      this.title = undefined
      this.$refs['form_field_columns'].reset()
      this.$refs['other_field_columns'].reset()
    },

    handleTree () {
      this.loading = true
      listProjectTree(0).then((res) => {
        if (res['code'] === 200) {
          if (this.modalType) {
            this.deleteThisItem(res.rows)
          }
          this.treeData = res.rows
        }
      }).finally(() => {
        this.loading = false
      })
    },

    deleteThisItem (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === this.key) {
          delete data[i]
        }
        if (data[i] && data[i].children) {
          this.deleteThisItem(data[i].children)
        }
      }
    }
  }
}
</script>
