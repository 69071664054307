import { axios } from '@/utils/request'

/**
 * 获取项目树
 * @param parentId 父级Id
 * @return
 */
export function listProjectTree(parentId) {
  return axios({
    url: '/patrol_config/list_project_tree',
    method: 'get',
    params: {parentId}
  })
}

/**
 * 获取项目/表单列表
 * @param params
 * @return
 */
export function list(params) {
  return axios({
    url: '/patrol_config/list',
    method: 'get',
    params: params
  })
}

/**
 * 获取单个项目配置
 * @param id 自增Id
 * @return
 */
export function getProjectConfig(id) {
  return axios({
    url: '/patrol_config/get_project_config',
    method: 'get',
    params: {id}
  })
}

/**
 * 获取单个表单配置
 * @param id 自增Id
 * @return
 */
export function getFormConfig(id) {
  return axios({
    url: '/patrol_config/get_form_config',
    method: 'get',
    params: {id}
  })
}


/**
 * 新增项目配置
 * @param data
 * @return
 */
export function addProjectConfig(data) {
  return axios({
    url: '/patrol_config/add_project_config',
    method: 'post',
    data: data
  })
}

/**
 * 修改项目配置
 * @param data
 * @return
 */
export function editProjectConfig(data) {
  return axios({
    url: '/patrol_config/edit_project_config',
    method: 'post',
    data: data
  })
}

/**
 * 新增表单配置
 * @param data
 * @return
 */
export function addFormConfig(data) {
  return axios({
    url: '/patrol_config/add_form_config',
    method: 'post',
    data: data
  })
}

/**
 * 修改表单配置
 * @param data
 * @return
 */
export function editFormConfig(data) {
  return axios({
    url: '/patrol_config/edit_form_config',
    method: 'post',
    data: data
  })
}

/**
 * 调整项目或表单启用状态
 * @param id
 * @return
 */
export function switchStatus(id) {
  return axios({
    url: '/patrol_config/switch_status',
    method: 'post',
    data: {id}
  })
}

/**
 * 删除目录或资源
 * @param id
 * @return
 */
export function del(id) {
  return axios({
    url: '/patrol_config/del',
    method: 'post',
    data: {id}
  })
}